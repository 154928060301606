import React, { useState } from "react";

import { Radio, Tabs } from "antd";

const { TabPane } = Tabs;

export const Live = () => {
  const [stage, setStage] = useState("main");

  return (
    <div className="track-page" style={{ paddingBottom: "20px" }}>
      <section className="full-width">
        <header style={{ flexWrap: "wrap" }}>
          <div style={{ width: "100%", textAlign: "center" }}>
            <h1>Live - Beta</h1>
          </div>

          <Radio.Group
            defaultValue="main"
            onChange={(e) => setStage(e.target.value as string)}
            style={{ marginBottom: 8 }}
          >
            <Radio.Button value="main">Main Stage</Radio.Button>
            <Radio.Button value="breakout">Breakout Stage</Radio.Button>
          </Radio.Group>
        </header>

        <div className="content-container">
          {stage === "main" ? (
            <div id="player-container" style={{ height: 500, background: "black" }}>
              <div style={{ height: 500, position: "relative" }}>
                <iframe
                  title="Main Stage - Primary Stream"
                  src="https://vimeo.com/event/2255122/embed"
                  frameBorder="0"
                  allow="autoplay; fullscreen; picture-in-picture"
                  allowFullScreen={true}
                  style={{ position: "absolute", top: "0", left: "0", width: "100%", height: "100%" }}
                />
              </div>
            </div>
          ) : (
            <div id="player-container" style={{ height: 500, background: "black" }}>
              <div style={{ height: 500, position: "relative" }}>
                <iframe
                  title="Breakout Stage - Primary Stream"
                  src="https://vimeo.com/event/2255120/embed"
                  frameBorder="0"
                  allow="autoplay; fullscreen; picture-in-picture"
                  allowFullScreen={true}
                  style={{ position: "absolute", top: "0", left: "0", width: "100%", height: "100%" }}
                />
              </div>
            </div>
          )}
        </div>
      </section>
    </div>
  );
};
