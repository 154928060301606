import React from "react";
import { Send } from "react-iconly";
import { Wrappers } from "components/Icons";
import { Dropdown, Menu } from "antd";
import { TalkEntity } from "app/infra/talk";
import { app } from "config";

interface ActionsProps {
  talk: TalkEntity;
  customText?: string;
}

export const Actions = ({ talk, customText }: ActionsProps) => {
  const link = `${app.apiUrl}/share/${talk.id}`;

  return (
    <div className="actions">
      <Dropdown
        overlay={(
          <Menu>
            <Menu.Item>
              <a
                target="_blank"
                rel="noopener noreferrer"
                href={`https://www.facebook.com/sharer/sharer.php?u=${link}&title=${talk.title}`}
              >
                Facebook
              </a>
            </Menu.Item>
            <Menu.Item>
              <a
                target="_blank"
                rel="noopener noreferrer"
                href={`https://twitter.com/share?text=${talk.title}&url=${link}&hashtags=AWasia`}
              >
                Twitter
              </a>
            </Menu.Item>
            <Menu.Item>
              <a
                target="_blank"
                rel="noopener noreferrer"
                href={`https://www.linkedin.com/shareArticle?mini=true&url=${link}&title=${talk.title}`}
              >
                Linkedin
              </a>
            </Menu.Item>
          </Menu>
        )}
      >
        <div className="ant-dropdown-trigger">
        <Send set="bulk" style={{ cursor: "pointer" }} />
        <span className="share-text">{customText}</span>
        </div>
      </Dropdown>
    </div>
  );
};
